<template>
  <div class="col-12 px-0 d-lg-none">
    <div class="row m-0 align-items-center justify-content-center g-1">
      <div class="col p-0">
        <MainMenu />
      </div>
      <div class="col-auto text-center">
        <img :src="logo" class="img-fluid cursor" :class="{logo: isUK, logoZa: !isUK}" @click="goTo('')">
      </div>
      <div class="col p-0">
        <div class="row mx-0 justify-content-end">
          <SwitchLocation />
          <div class="col-auto ps-1 text-right">
            <SearchItem align="right" @click="goTo('search')" size="xsmall">
              <IconSearch2 color="gold" class="px-0" />
            </SearchItem>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue'

export default {
  components: {
    SearchItem: defineAsyncComponent(() => import('@/components/SearchItem.vue')),
    MainMenu: defineAsyncComponent(() => import('./MainMenu.vue')),
    IconSearch2: defineAsyncComponent(() => import('@/components/icons/IconSearch2.vue')),
    SwitchLocation: defineAsyncComponent(() => import('@/components/location/SwitchLocation.vue'))
  },
  name: 'TopMenuLayoutMobile',
  data () {
    return {
      logo: process.env.VUE_APP_LOGO
    }
  },
  computed: {
    isUK () {
      if (process.env.VUE_APP_COUNTRY === 'UK') {
        return true
      } else {
        return false
      }
    }
  },
  methods: {
    goTo (val) {
      window.scrollTo(0, 0)
      this.$router.push('/' + val)
    }
  }
}
</script>

<style scoped>
.logo  {
  max-height: 58px;
  max-width: 40vw;
  width: 180px;
}
.logoZa  {
  max-height: 75px;
  max-width: 40vw;
  width: 180px;
}
</style>
